<template>
  <div>
    <div class="Zhanpingbanner">
      <img class="Zhanpingbannerpc" :src="img" alt="" />
      <img class="Zhanpingbannerios" :src="imgios" alt="" />
    </div>
    <div class="jiaoyu">
      <div class="chengguotype">
        <div class="chengguotype_l">
          <ul class="chengguotype_big">
            <li>专业分类</li>
            <li @click="type_click(0)">全部</li>
            <li @click="type_click(22)">环境设计</li>
            <li @click="type_click(23)">产品设计</li>
            <li @click="type_click(24)">视觉传达设计</li>
          </ul>
          <ul class="chengguotype_small">
            <li>收费情况</li>
            <li @click="p_typeclick(0)">全部</li>
            <li @click="p_typeclick(1)">免费</li>
            <li @click="p_typeclick(2)">收费</li>
          </ul>
        </div>
      </div>
      <ul class="jiaoyu_ul">
        <li
          class="jiaoyu_ulli"
          v-for="item in jiaoyu_ul"
          :key="item.p_id"
          :class="item.title ? '' : 'Zhanping_bisheullizero'"
          @click="jiaoyu_ulliclick(item.p_id)"
        >
          <div class="jiaoyu_ulliimg">
            <img v-lazy="item.title_pic" :alt="item.title" />
          </div>
          <div class="jiaoyu_ullicenter">
            <img v-lazy="item.userpic" alt="" />
            <span>{{ item.username }}</span>
            <span>{{ item.money > 0 ? "￥"+item.money+"" : "免费" }}</span>
          </div>
          <div class="jiaoyu_ullibtm">
            <div class="jiaoyu_ullibtm_rd">
               <icon-svg icon-class="iconlook" />{{ item.onclick==null?'0':item.onclick }}
            </div>
            <div class="jiaoyu_ullibtm_zan">
               <icon-svg icon-class="icongood" />{{ item.dznum==null?'0':item.dznum }}
            </div>
            <div class="jiaoyu_ullibtm_zan">
               <icon-svg icon-class="iconvedio" />{{ item.video_num}}
            </div>
            <div>{{ item.create_time }}</div>
          </div>
          <div class="jiaoyu_ullibtm_btm">
            <div>{{item.title}}</div>
            <div>{{item.p_type==22?'环境设计':(item.p_type==23?'产品设计':"视觉传达设计")}}</div>
          </div>
        </li>
        <li class="lizero" v-if="lizero"><img src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png" alt=""></li>
      </ul>
      <el-pagination
      :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".jiaoyu .chengguotype_big li", function () {
  $(this).css({ color: "#4FC6FF" });
  $(this).siblings().css({ color: "#333" });
  $(".jiaoyu .chengguotype_small li").eq(1).css({ color: "#4FC6FF", "border-bottom": "2px solid #4FC6FF" });
  $(".jiaoyu .chengguotype_small li").eq(1).siblings().css({ color: "#333", "border-bottom": "none" });
});
$(document).on("click", ".jiaoyu .chengguotype_small li", function () {
  $(this).css({ color: "#4FC6FF", "border-bottom": "2px solid #4FC6FF" });
  $(this).siblings().css({ color: "#333", "border-bottom": "none" });
});
export default {
  components: {},
  data() {
    return {
      lizero:false,
      img: "https://task.hozoin.cn/Works/20210408/sj-2225509702.png",
      imgios:"https://task.hozoin.cn/Works/20210409/sj-6489117111.png",
      charge: 0,
      p_type: 0,
      jiaoyu_ul: [],
      total: 0,
      pagesize: 8,
      userinfo :{},
    };
  },
  methods: {
    jiaoyu_ulliclick(id) {
      this.$router.push({ path: "/jiaoyu_heji", query: { data: id } });
    },
    type_click(id) {
      var that = this;
      that.p_type = id;
      that.jiaoyu_ul = [];
      that.charge = 0;
      that.axiosclick();
    },
    p_typeclick(id) {
      var that = this;
      that.charge = id;
      that.jiaoyu_ul = [];
      that.axiosclick();
    },
    axiosclick() {
      var that = this;
      that.jiaoyu_ul = [];
      that.lizero=true;
      this.$axios({
        url:"/api/getPosterList",
        method: "post",
        data: {
          page: "1",
          limit: that.pagesize,
          p_type: that.p_type,
          charge: that.charge,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero=false;
          that.total = res.data.result.count;
          that.jiaoyu_ul = res.data.result.list;
          var count = res.data.result.list.length % 4;
          var li = "";
          if (count == 2) {
            that.jiaoyu_ul.push(li, li);
          } else if (count == 3) {
            that.jiaoyu_ul.push(li);
          }
        } else {
          that.lizero=true;
        }
      });
    },
    currentchange(index) {
      var that = this;
      that.jiaoyu_ul = [];
      this.$axios({

        url:"/api/getPosterList",
        method: "post",
        data: {
          page: index,
          p_type: that.p_type,
          charge: that.charge,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.total = res.data.result.count;
          that.jiaoyu_ul = res.data.result.list;
          var count = res.data.result.list.length % 4;
          var li = "";
          if (count == 2) {
            that.jiaoyu_ul.push(li, li);
          } else if (count == 3) {
            that.jiaoyu_ul.push(li);
          }
        } else {
          console.log("请求失败");
        }
      });
    },
  },
  created() {
    this.fenxiang(
          "和作营-教育视频",
          "观看各大名校教师讲课，不上名校同样学习，把握每一个学习机会。",
          "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
        );
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.$axios({

      url:"/api/getPosterList",
      method: "post",
      data: {
        page: "1",
        limit: that.pagesize,
        p_type: that.p_type,
        charge: that.charge,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.total = res.data.result.count;
        that.jiaoyu_ul = res.data.result.list;
        var count =res.data.result.list.length % 4;
        var li = "";
        if (count == 2) {
          that.jiaoyu_ul.push(li, li);
        } else if (count == 3) {
          that.jiaoyu_ul.push(li);
        }
      } else {
        console.log("请求失败");
      }
    });
  },
};
</script>
<style>
@import "../assets/css/jiaoyu.css";
</style>
